import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../Auth/AuthProvider";

const PaymentFail = () => {
  const navigate = useNavigate();
  const { isLoggedIn, loading } = useContext(AuthContext);
  
  useEffect(() => {
    if (!loading && !isLoggedIn) {
      navigate("/");
    }
  }, [loading, isLoggedIn, navigate]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex flex-col min-h-screen">
      {/* Header */}
      <header className="bg-red-600 text-white py-4 shadow-md">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-3xl font-bold">Omolive.online</h1>
        </div>
      </header>

      {/* Main Content */}
      <main className="flex-grow flex justify-center items-center bg-gray-100 py-8">
        <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md text-center">
          <h2 className="text-2xl font-bold text-red-600 mb-4">Payment Failed</h2>
          <p className="text-gray-700 mb-4">Unfortunately, your payment could not be processed. Please try again.</p>
          <button
            onClick={() => navigate("/")}
            className="bg-red-500 text-white py-2 px-4 rounded w-full hover:bg-red-600 transition duration-200"
          >
            Go to Home
          </button>
        </div>
      </main>

      {/* Footer */}
      <footer className="bg-red-600 text-white py-4">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 Omolive.online. All rights reserved.</p>
          <p>
            For support, contact us at{" "}
            <a href="mailto:support@omelive.online" className="underline">
              support@omelive.online
            </a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default PaymentFail;
